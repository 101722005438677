import { useState } from "react";
import emailjs from "emailjs-com";
import { useRef } from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const lastRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm(
        "service_0betuho",
        "template_1cobwrj",
        e.target,
        "HWYIp170yKBLL28i0"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          e.target.reset();
          alert("Üzenet elküldve", result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div id="bottom-section">
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Kérj időpontot</h2>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        ref={firstRef}
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Név"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        ref={secondRef}
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    ref={lastRef}
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Üzenet"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Üzenet küldése
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item"></div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li className="sip">
                    {" "}
                    <div className="logo">
                      <a href={props.data ? props.data.logo : "/"}>
                        <i>
                          <img src="img/fejlec-logo.svg" alt="Sipos logo" />
                        </i>
                      </a>
                    </div>
                  </li>
                  <li className="face">
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            2023{" "}
            <a href="http://www.dews.hu" rel="">
              DEWS{" "}
            </a>
            Design by{" "}
            <a href="https://www.facebook.com/barandesign123" rel="design">
              Baran Design
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
